import React, { useState } from "react";

const ContactPage: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <main className="bg-black text-green-400 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-4xl p-4">
        {" "}
        {/* Changed max-w-lg to max-w-4xl */}
        <header>
          <h1 className="text-4xl mb-6 font-mono typing-effect">
            Contact Us<span className="small-blinking-pipe">|</span>
          </h1>
          <p className="text-sm text-gray-400 mb-6">
            Get in touch using the form below.
          </p>
        </header>
        <section className="bg-gray-900 p-4 rounded-lg shadow-lg terminal-box">
          {!submitted ? (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-mono text-gray-400"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-black text-green-400 border border-green-400 p-2 rounded w-full focus:outline-none font-mono"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-mono text-gray-400"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-black text-green-400 border border-green-400 p-2 rounded w-full focus:outline-none font-mono"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-mono text-gray-400"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  className="bg-black text-green-400 border border-green-400 p-2 rounded w-full focus:outline-none font-mono"
                  placeholder="Enter your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <button
                type="submit"
                className="bg-green-600 hover:bg-green-700 p-2 rounded font-mono hacker-button w-full"
              >
                Send Message
              </button>
            </form>
          ) : (
            <div className="text-center">
              <h2 className="text-2xl font-mono mb-4">Thank You!</h2>
              <p className="text-gray-400">
                Your message has been submitted successfully.
              </p>
            </div>
          )}
        </section>
        <footer className="mt-8 text-center">
          <a href="/" className="hacker-link">
            Go back to Home
          </a>
        </footer>
      </div>
    </main>
  );
};

export default ContactPage;
