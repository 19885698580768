import React from "react";

const ApiDocPage: React.FC = () => {
  return (
    <main className="bg-black text-green-400 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-4xl p-4">
        <header>
          <h1 className="text-4xl mb-6 font-mono typing-effect">
            API Documentation<span className="small-blinking-pipe">|</span>
          </h1>
          <p className="text-sm text-gray-400 mb-6">
            This is a placeholder for the API documentation. Use this page to
            explore the endpoints and interact with the DNS Lookup API.
          </p>
        </header>

        <section className="bg-gray-900 p-4 rounded-lg shadow-lg terminal-box">
          <h2 className="text-3xl font-mono mb-4">Available Endpoints</h2>

          <ul className="text-green-300 font-mono space-y-4">
            <li>
              <span className="text-lime">GET</span> /api/dnslookup?domain=
              {"{domain}"}
              <p className="text-sm text-gray-400">
                Fetch the DNS records for the specified domain. Replace{" "}
                <span className="text-lime">{"{domain}"}</span> with the domain
                name.
              </p>
            </li>

            <li>
              <span className="text-lime">GET</span> /api/status
              <p className="text-sm text-gray-400">
                Check the API status to ensure it's up and running.
              </p>
            </li>

            <li>
              <span className="text-lime">POST</span> /api/advancedlookup
              <p className="text-sm text-gray-400">
                Perform an advanced DNS lookup with additional options
                (placeholder).
              </p>
            </li>
          </ul>
        </section>

        <footer className="mt-8">
          <a href="/" className="hacker-link">
            Go back to Home
          </a>
        </footer>
      </div>
    </main>
  );
};

export default ApiDocPage;
