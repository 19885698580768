import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const EntryPage: React.FC = () => {
  const [domain, setDomain] = useState<string>("");
  const [dnsRecords, setDnsRecords] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isValidDomain, setIsValidDomain] = useState<boolean>(true);

  if (process.env.REACT_APP_DEBUG_MODE === "true") {
    console.log("API URL:", process.env.REACT_APP_API_URL);
    console.log("API Key:", process.env.REACT_APP_BACKEND_API_KEY);
  }

  const validateDomain = (domain: string): boolean => {
    const domainRegex = /^(?!-)[A-Za-z0-9-]+(\.[A-Za-z]{2,})+$/;
    return domainRegex.test(domain);
  };

  const fetchDNSRecords = async () => {
    if (!validateDomain(domain)) {
      setIsValidDomain(false);
      return;
    }

    setIsValidDomain(true);
    setLoading(true);
    setError(null);

    ReactGA.event({
      category: "Domain Lookup",
      action: "lookup",
      label: domain,
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "API-KEY": process.env.REACT_APP_BACKEND_API_KEY || "",
        },
        body: JSON.stringify({ domain }),
        credentials: "same-origin",
      });

      if (!response.ok) {
        const errorMsg =
          response.status === 403
            ? "Access to the API is forbidden. Please check your permissions."
            : `Failed to fetch DNS records: ${response.statusText}`;
        throw new Error(errorMsg);
      }

      const data = await response.json();
      setDnsRecords(data);
    } catch (error: any) {
      setError(
        error.message || "Error fetching DNS records. Please try again."
      );
      if (process.env.REACT_APP_DEBUG_MODE === "true") {
        console.error("Error fetching DNS records:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDomain = e.target.value;
    setDomain(newDomain);

    if (error || !isValidDomain) {
      setError(null);
      setIsValidDomain(true);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <main
      className={`bg-black text-${
        isValidDomain ? "green-400" : "red-500"
      } min-h-screen flex items-center justify-center ${
        isFocused ? "terminal-background-active" : ""
      }`}
    >
      <div className="w-full max-w-lg p-4 md:max-w-2xl lg:max-w-4xl">
        <header className="mb-6">
          <h1
            className={`text-3xl md:text-4xl lg:text-5xl font-mono typing-effect grunge-title ${
              isValidDomain ? "text-green-400" : "text-red-500"
            }`}
          >
            DNS Lookup Tool<span className="small-blinking-pipe">|</span>
          </h1>
          <p
            className={`text-sm md:text-base mb-4 ${
              isValidDomain ? "text-gray-400" : "text-red-500"
            }`}
          >
            Welcome to DNS Peek, your go-to tool for quick and reliable DNS
            health checks, DNS records lookups, and domain blacklist monitoring.
            Whether you're troubleshooting domain issues, verifying DNS
            configurations, or ensuring your domain is clear from mail
            blacklists, our tool provides a fast and accurate analysis—all for
            free! Simply enter your domain in the box to the right, click the
            button, and get a detailed report within seconds. Check your DNS now
            and keep your domain running smoothly with DNS Peek.
          </p>
        </header>
        <section
          className={`bg-gray-900 p-4 md:p-6 lg:p-8 rounded-lg shadow-lg terminal-box ${
            isFocused ? "terminal-box-active" : ""
          }`}
          style={{
            boxShadow: `0px 0px 20px ${isValidDomain ? "green" : "red"}`,
          }}
        >
          <div className="flex flex-col space-y-3">
            <label htmlFor="domain-input" className="sr-only">
              Domain Input
            </label>
            <input
              id="domain-input"
              type="text"
              className={`bg-black text-${
                isValidDomain ? "green-400" : "red-500"
              } border border-${
                isValidDomain ? "green-400" : "red-500"
              } p-2 rounded focus:outline-none font-mono ${
                isFocused ? "input-focused" : ""
              }`}
              placeholder="Enter domain (e.g., example.com)"
              value={domain}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <button
              className={`${
                isValidDomain
                  ? "bg-green-600 hover:bg-green-700 text-green-400"
                  : "bg-black border-red-500 text-red-500 invalid-domain"
              } p-2 rounded font-mono hacker-button`}
              style={{
                boxShadow: isValidDomain
                  ? "0px 0px 10px lime"
                  : "0px 0px 10px red",
                borderColor: isValidDomain ? "lime" : "red",
                textShadow: isValidDomain
                  ? "0 0 5px lime, 0 0 10px lime"
                  : "0 0 5px red, 0 0 10px red",
              }}
              onClick={fetchDNSRecords}
              aria-label="Lookup DNS"
            >
              {isValidDomain ? (
                loading ? (
                  "Looking up..."
                ) : (
                  "Lookup DNS"
                )
              ) : (
                <span style={{ textShadow: "0px 0px 10px red", color: "red" }}>
                  Invalid domain name. Please enter a valid domain.
                </span>
              )}
            </button>
          </div>

          {error && (
            <div className="mt-4 text-red-500">
              <p>{error}</p>
            </div>
          )}

          {dnsRecords && (
            <div className="mt-6">
              <h2 className="text-xl md:text-2xl font-mono">
                DNS Records for {domain}:
              </h2>
              <div className="bg-black text-green-300 p-4 rounded overflow-x-auto mt-3 font-mono terminal-output">
                {/* NS Records */}
                <h3 className="text-lg md:text-xl">Nameservers:</h3>
                {dnsRecords.results.NS.value ? (
                  <ul className="list-disc ml-6">
                    {dnsRecords.results.NS.value.map(
                      (ns: string, index: number) => (
                        <li key={index}>{ns}</li>
                      )
                    )}
                  </ul>
                ) : (
                  <p>No NS records found.</p>
                )}
                <p>Status: {dnsRecords.results.NS.status}</p>

                {/* MX Records */}
                <h3 className="text-lg md:text-xl mt-4">MX Records:</h3>
                {dnsRecords.results.MX.value ? (
                  <ul className="list-disc ml-6">
                    {dnsRecords.results.MX.value.map(
                      (mx: string, index: number) => (
                        <li key={index}>{mx}</li>
                      )
                    )}
                  </ul>
                ) : (
                  <p>No MX records found.</p>
                )}
                <p>Status: {dnsRecords.results.MX.status}</p>
              </div>
            </div>
          )}
        </section>

        <footer className="mt-8 text-center">
          <nav
            className={`flex justify-center space-x-4 md:space-x-8 ${
              isValidDomain ? "text-green-400" : "text-red-500"
            }`}
          >
            <Link to="/api" className="hacker-link">
              API
            </Link>
            <Link to="/about" className="hacker-link">
              About
            </Link>
            <Link to="/contact" className="hacker-link">
              Contact
            </Link>
          </nav>
        </footer>
      </div>
    </main>
  );
};

export default EntryPage;
