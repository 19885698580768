import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";

const rootElement = document.getElementById("root");

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === "true") {
  const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  if (trackingId) {
    ReactGA.initialize(trackingId);
    ReactGA.send("pageview");
  } else {
    console.error("Google Analytics ID is not provided.");
  }
}

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
