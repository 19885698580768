import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import EntryPage from "./pages/EntryPage";
import ApiDocPage from "./pages/ApiDocPage";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EntryPage />} />
        <Route path="/api" element={<ApiDocPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />{" "}
        {/* Add AboutPage route */}
      </Routes>
    </Router>
  );
};

export default App;
