import React from "react";

const AboutPage: React.FC = () => {
  return (
    <main className="bg-black text-green-400 min-h-screen flex items-center justify-center">
      <div className="w-full max-w-4xl p-4">
        <header>
          <h1 className="text-4xl mb-6 font-mono typing-effect">
            About Us<span className="small-blinking-pipe">|</span>
          </h1>
          <p className="text-sm text-gray-400 mb-6">
            Welcome to the DNS Lookup Tool! We are a group of passionate
            individuals who built this tool for DNS geeks and cybersecurity
            enthusiasts. This tool enables you to query DNS records in a
            hacker-style, interactive interface. Here is some more information
            about us.
          </p>
        </header>

        <section className="bg-gray-900 p-4 rounded-lg shadow-lg terminal-box">
          <h2 className="text-3xl font-mono mb-4">Our Mission</h2>
          <p className="text-green-300 font-mono mb-6">
            Our mission is simple: to provide an easy-to-use, console-like tool
            for querying DNS records. We believe that looking up domain
            information should be fun, fast, and informative. That’s why we’ve
            crafted this tool to feel like you’re in the command line—because
            let’s face it, it’s just cooler that way.
          </p>

          <h2 className="text-3xl font-mono mb-4">Our History</h2>
          <p className="text-green-300 font-mono mb-6">
            The DNS Lookup Tool was born from the idea of making DNS lookups
            more accessible and, frankly, more entertaining. We started as a
            small team of network engineers, hackers, and developers. After
            countless hours spent working with command-line tools, we thought:
            why not create a tool that merges the powerful aesthetics of a
            hacker console with the technical utility of DNS querying? And thus,
            this tool was born.
          </p>

          <h2 className="text-3xl font-mono mb-4">What We Offer</h2>
          <p className="text-green-300 font-mono mb-6">
            This tool allows you to perform simple and advanced DNS queries. You
            can look up A, CNAME, MX, and many other types of DNS records. We
            also offer advanced lookup features that allow for customizable
            queries.
          </p>

          <h2 className="text-3xl font-mono mb-4">Our Team</h2>
          <p className="text-green-300 font-mono mb-6">
            Our team consists of seasoned developers and network specialists who
            have been working in the cybersecurity and IT industries for years.
            We're here to make sure you have the best experience querying DNS
            records.
          </p>

          <h2 className="text-3xl font-mono mb-4">Contact Us</h2>
          <p className="text-green-300 font-mono mb-6">
            Have questions? Want to know more about our tool? Feel free to reach
            out to us via our{" "}
            <a href="/contact" className="hacker-link">
              Contact Page
            </a>
            .
          </p>
        </section>

        <footer className="mt-8 text-center">
          <a href="/" className="hacker-link">
            Go back to Home
          </a>
        </footer>
      </div>
    </main>
  );
};

export default AboutPage;
